import React from "react"
import PropTypes from "prop-types"

import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const Tags = ({ pageContext, data, location }) => {
    const siteTitle = data.site.siteMetadata.title

    const { tag } = pageContext
    const { edges } = data.allMarkdownRemark
    // const tagHeader = `${totalCount} post${
    //     totalCount === 1 ? "" : "s"
    // } tagged with "${tag}"`

    return (
        <Layout location={location} title={siteTitle}>
            <SEO
                title={`Posts on ${tag}`}
                keywords={[
                    `blog`,
                    `html`,
                    `javascript`,
                    `react`,
                    `css`,
                    `accessibility`,
                    `career`,
                ]}
            />
            <div className="width-container">
                <h1
                    style={{
                        textTransform: "capitalize",
                    }}
                >
                    #{tag}
                </h1>
                <ul style={{ marginLeft: 0 }}>
                    {edges.map(({ node }) => {
                        const { slug } = node.fields
                        const { title, date, description } = node.frontmatter
                        return (
                            <li
                                key={slug}
                                style={{
                                    marginBottom: rhythm(2),
                                }}
                            >
                                <small>{date}</small>
                                <h2
                                    style={{
                                        marginBottom: rhythm(1 / 3),
                                        marginTop: rhythm(1 / 4),
                                        fontSize: rhythm(1.2),
                                    }}
                                >
                                    <Link to={slug} className="blog-post-link">
                                        {title}
                                    </Link>
                                </h2>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: description || node.excerpt,
                                    }}
                                />
                            </li>
                        )
                    })}
                </ul>
                <Link
                    to="/tags"
                    style={{
                        fontSize: 20,
                        color: "var(--primary-color)",
                        borderBottom: "2px solid currentColor",
                    }}
                >
                    All tags
                </Link>
            </div>
        </Layout>
    )
}

Tags.propTypes = {
    pageContext: PropTypes.shape({
        tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            totalCount: PropTypes.number.isRequired,
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                        }),
                        fields: PropTypes.shape({
                            slug: PropTypes.string.isRequired,
                        }),
                    }),
                }).isRequired
            ),
        }),
    }),
}

export default Tags

export const pageQuery = graphql`
    query($tag: String) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            limit: 1000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`
